export const VIEW_CONTRACT = '0xBdef2BC15cc1C11AE5EdF9638830fA7e7f867142'; //MAINNET
export const VESTING_CONTRACT = { //MAINNET
  strategic: '0xDC0A2af672E4467F6363b765742b14919b9d6510',
  private_t1: '0xce0BfF552BeB3070eEEc4e2dC41aB8cCe9a598b3',
  private_t2: '0x602575374a3A02A7d6f6928055FA13940E720415',
  kol: '0xde46cF01561B68b4DF4F32502768aF47240DDdde',
  team: '',
  advisory: '',
  seed: '0x5AD5Ed0fC0f8213Ee6D7473dB913da43C272e244',
  'pre-seed': '0xB383b7B32A5670B59dE77208A6EBD6C73e6b1E8f',
  launchpads: '0x0c415EdEE224D5f5a2292D739f1a3D3A01C8C69E',
  '12/24': '0x71C8761B4fBeaA991e0D96260476956425dB8d7E',
  '12/36': '0xDFCf971B561b4d16a8e3311113f8A9F2F553A240',
  '18/24': '0x0815c6bBd5b4F2B381D566447Da7122D3520c0d8',
  '8/4': '0x4A78116e541639eA3633242f6457018B48AeAd57',
  '8/16': '0x3AF23Ee04Cf73fB0375CC4A8112f1208588e0e08',
  '8/17': '0x684aB9455167569e37096236C7b48F175aadDc2c',
}
// export const VIEW_CONTRACT = '0xc56202638C9172b421f330c7A1E97Aed669389Cf'; //KOVAN
// export const VESTING_CONTRACT = { //KOVAN
//   strategic: '0x09439181815bC7E3341585C4d683739f57e33286',
//   private_t1: '0xb96c4e1c7442284919a735E3C55CD721b040d663',
//   private_t2: '0x9b4C22c2206C4d0e1D99D83b398e4Cf4d8b4aeDd',
//   kol: '0x23411E921e9C6d231B8c5301f3E1Add626045b03',
//   team: '0x673a1C282589544Fb8F064f63A1f05fF3C54A5E1',
//   advisory: '0x2a005C3B74C5E037E2Ec072bc4018d0553B74Dd4',
//   seed: '0xE6348E406F72b439b6df39f8328b741942182e68',
//   'pre-seed': '0xaC5a129A7C2CDB675d44A477D7ab5713f5Af68c4',
//   launchpads: '0x87F2246df31Afa331Cd245EFd233BDcc30E64399',
//   treasury: '0x8F3eED2F9024c826012e3c415bE295cae3Ffaaa1',
//   community: '0xDcF5880cA79573b05F84e324531881A91386CB99',
// }


