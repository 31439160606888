import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import * as routePaths from 'constants/routes';
import Loader from 'shared/components/Loader';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { EstimatedPriceActions } from 'store/reducers/estimatedPrice';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkActions } from 'store/reducers/network';
import { walletConnector } from 'helpers/connectors';
import { WalletActions } from 'store/reducers/wallet';

const Home = lazy(() => import('scenes/Home'));
const Details = lazy(() => import('scenes/Details'));
const Stake = lazy(() => import('scenes/Stake'));
const Rewards = lazy(() => import('scenes/Rewards'));
const Dashboard = lazy(() => import('scenes/Dashboard'));
const LaunchPads = lazy(() => import('scenes/Launchpads'));
const Investments = lazy(() => import('scenes/Investments'))

declare const window: any;

const loader = <Loader />;

const Routes = () => {
  const dispatch = useDispatch();
  const fetchedData = useSelector(state => state.pool.data);

  useEffect(() => {
    if(window.ethereum) {
      window.ethereum
        .request({
          method: 'eth_chainId',
        })
        .then((chainId: number) => {
          // @ts-ignore
          dispatch(NetworkActions.setChainID({ chainId: +chainId }))
        });

      window.ethereum.on('chainChanged', (chainId: string) => {
        window.location.reload()
      })
      window.ethereum.on('accountsChanged', (accounts: any[]) => {
        window.location.reload()
      })
    }
  }, []);

  useEffect(() => {
    return () => {
      const loginType = localStorage.getItem('loginType');

      if (walletConnector.connected && loginType === 'walletconnect') {
        dispatch(WalletActions.disconnectWallet())
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (fetchedData) {
      dispatch(EstimatedPriceActions.getEstimatedPriceRequest());
    }
  }, [fetchedData])

  return (
    <Suspense fallback={loader}>
      <Switch>
        <PublicRoute path={routePaths.ROUTE_HOME} component={Home} exact fullwidth />
        <PrivateRoute path={routePaths.ROUTE_REWARDS} component={Rewards} exact />
        <PrivateRoute path={`${routePaths.ROUTE_DETAILS}/:poolAddress`} component={Details} exact />
        <PrivateRoute path={`${routePaths.ROUTE_STAKING}/:poolAddress`} component={Stake} exact />
        <PrivateRoute path={routePaths.ROUTE_DASHBOARD} component={Dashboard} exact />
        <PrivateRoute path={routePaths.ROUTE_LAUNCHPADS} component={LaunchPads} exact />
        <PrivateRoute path={routePaths.ROUTE_INVESTMENTS} component={Investments} exact />
        <Redirect to={routePaths.ROUTE_HOME} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
