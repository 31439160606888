import Web3 from 'web3';
import { walletConnector } from './connectors';

import { bscTestnetNode, ethAlchemyTestnetNode } from './url';

const urEthProvider = `${ethAlchemyTestnetNode}${process.env.REACT_APP_ALCHEMY_PROJECT_ID}`;
const urlBscProvider = bscTestnetNode;

declare const window: any;

export const LOGIN_TYPE_KEY = 'loginType'

export const METAMASK_TYPE = 'metamask'
export const WALLET_CONNECT_TYPE = 'walletconnect'

const web3Service = new Web3(
  new Web3.providers.HttpProvider(urEthProvider || urlBscProvider),
);

const getWeb3 = (): Web3 => {
  const walletconnect = JSON.parse(localStorage.getItem('walletconnect') as string);
  const loginType = localStorage.getItem(LOGIN_TYPE_KEY)

  if (walletconnect && loginType === WALLET_CONNECT_TYPE) {
    //@ts-ignore
    web3Service.setProvider(walletConnector);
  } else if (window.ethereum && loginType === METAMASK_TYPE && !walletconnect) {
    web3Service.setProvider(window.ethereum);
  }

  return web3Service;
};

const web3 = getWeb3();

export { web3, getWeb3 };
