import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WalletState } from './types';
import WalletConnectProvider from '@walletconnect/web3-provider';

const initialState: WalletState = {
  address: '',
  loading: false,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletRequest: (
      state,
      { payload }: PayloadAction<{ address: string; callBack?(): void }>,
    ) => {
      state.loading = true;
      state.error = false;
    },
    setWalletSuccess: (state, { payload: { address } }: PayloadAction<{ address: string }>) => {
      state.address = address;
      state.loading = false;
      state.error = false;
    },
    setWalletFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    connectWallet: (state, { payload }: PayloadAction<{ callback?: () => void, type: string }>) => {
      state.loading = true;
    },
    disconnectWallet: (state) => {
      state.address = '';
      state.loading = false;
      state.error = false;
    },
    trustConnect: (state) => {
      state.loading = true;
    },
    trustConnectSuccess: (state, action: PayloadAction<WalletConnectProvider>) => {
      state.connector = action.payload;
      state.loading = false;
    },
    trustConnectError: (state) => {
      state.connector = undefined;
      state.loading = false;
    },
  },
});

export { actions as WalletActions };

export default reducer;
