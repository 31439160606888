import { put, call } from 'redux-saga/effects';
import { web3, getWeb3, LOGIN_TYPE_KEY, METAMASK_TYPE } from 'helpers/getWeb3';

import { MetaMaskActions } from 'store/reducers/metamask';
import { WalletActions } from '../reducers/wallet';

import { walletConnector } from 'helpers/connectors';

declare const window: any;

export function* disconnectWalletSaga() {
  const loginType = localStorage.getItem('loginType');

  yield put(MetaMaskActions.setLoading(false));

  if (loginType === 'walletconnect') {
    yield walletConnector.disconnect();
    yield walletConnector.close();
  }

  yield call(setLocalSaga, false);
  yield put(MetaMaskActions.setLoading(false));
}

export function* setLocalSaga(connected: boolean) {
  yield localStorage.setItem('connected', JSON.stringify(connected));
}

export function* mobileConnectSaga() {
  try {
    yield walletConnector.enable();

    yield walletConnector.connector.connect()

    if (walletConnector.accounts.length) {
      yield put(WalletActions.setWalletRequest({ address: walletConnector.accounts[0] }));
    }
  } catch (error) {
    console.log(error)
    yield put(WalletActions.disconnectWallet());
  }
}

export function* webConnectSaga(type: string) {
  try {
    const web3Service = getWeb3();
    const accounts: string[] = yield web3Service.eth.requestAccounts();
    const address = accounts[0];

    yield put(WalletActions.setWalletRequest({ address }));
    yield put(MetaMaskActions.setLoading(false));
  } catch (error) {
    yield put(WalletActions.disconnectWallet());
  }
}

export function* connectWalletSaga(action: ReturnType<typeof WalletActions.connectWallet>) {
  const { callback, type } = action.payload;

  yield put(MetaMaskActions.setLoading(true));
  try {

    localStorage.setItem(LOGIN_TYPE_KEY, type)

    if (type === METAMASK_TYPE) {
      yield call(webConnectSaga, type)
    } else {
      yield call(mobileConnectSaga)
    }
    const accounts: string[] = yield web3.eth.requestAccounts();

    if (accounts) {
      yield call(setLocalSaga, true);

      yield put(MetaMaskActions.setLoading(false));

      callback && callback();
    }
  } catch (error) {
    const disconnectActionErrorCodes = [4001, -32002]
    if (disconnectActionErrorCodes.includes((error as any).code)) {
      yield put(WalletActions.disconnectWallet());
    }

    console.log((error as any).code)

    yield put(WalletActions.setWalletFailure());
    // yield put(MetaMaskActions.setLoading(false))
  }
}

// export function* trustConnectSaga() {
//   try {
//     const connector = new WalletConnect({
//       bridge: 'https://bridge.walletconnect.org',
//       qrcodeModal: QRCodeModal,
//     });

//     if (connector.connected) {
//       yield connector.killSession();
//     }
//     yield connector.createSession();

//     yield put(WalletActions.trustConnectSuccess(connector));
//   } catch (error) {
//     yield put(WalletActions.trustConnectError());
//     yield put(WalletActions.disconnectWallet());
//   }
// }

export function* getWalletSaga() {
  const connected = JSON.parse(localStorage.getItem('connected') as string);

  if (window.ethereum && connected) {
    try {
      const accounts: string[] = yield web3.eth.getAccounts();

      if (accounts.length) {
        yield put(WalletActions.setWalletRequest({ address: accounts[0] }));
      } else {
        yield put(WalletActions.setWalletSuccess({ address: '' }));
      }
    } catch (error) {
      yield put(WalletActions.disconnectWallet());
    }
  }
}

export function* setWalletSaga(action: ReturnType<typeof WalletActions.setWalletRequest>) {
  const { address } = action.payload;

  try {
    yield put(WalletActions.setWalletSuccess({ address }));
  } catch (error) {
    yield call(disconnectWalletSaga);
    yield put(WalletActions.disconnectWallet());
  }
}
