import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VestingState, VestingPool } from './types';

const initialState: VestingState = {
  beneficiaryType: '',
  loading: false,
  error: false,
  isBeneficiary: false,
  amount: 0,
  claimable: 0,
  beneficiaryTypeModal: false,
  addBeneficiaryError: false,
  addBeneficiaryLoading: false,
  vestingDataError: false,
  vestingDataLoading: false,
  vestingClaimError: false,
  vestingClaimLoading: false,
  vestingPoolsError: false,
  vestingPoolsLoading: false,
  vestingPools: [{ type: '', amount: 0, claimable: 0 }],
};

const { actions, reducer } = createSlice({
  name: 'vesting',
  initialState,
  reducers: {
    checkBeneficiaryRequest: (state, action: PayloadAction<{ address: string, type?: string }>) => {
      state.loading = true
      state.error = false
    },
    checkBeneficiarySuccess: (state, action: PayloadAction<{ isBeneficiary: boolean }>) => {
      state.isBeneficiary = action.payload.isBeneficiary
      state.loading = false
    },
    checkBeneficiaryFailure: (state) => {
      state.loading = false
      state.error = true
      state.isBeneficiary = false
    },
    addBeneficiaryRequest: (state, action: PayloadAction<{ address: string; type: string}>) => {
      state.addBeneficiaryLoading = true
      state.addBeneficiaryError = false
    },
    addBeneficiarySuccess: (state) => {
      state.addBeneficiaryLoading = false
    },
    addBeneficiaryFailure: (state) => {
      state.addBeneficiaryError = true
      state.addBeneficiaryLoading = false
    },
    setAmount: (state, action: PayloadAction<{ amount: number }>) => {
      state.amount = action.payload.amount
    },
    toggleBeneficiaryTypeModal: (state, action:PayloadAction<{isOpen: boolean}>) => {
      state.beneficiaryTypeModal = action.payload.isOpen
    },
    vestingDataRequest: (state, action: PayloadAction<{ address: string, type: string }>) => {
      state.vestingDataError = false
      state.vestingDataLoading = true
    },
    vestingDataSuccess: (state, action: PayloadAction<{ amount: number, claimable: number }>) => {
      state.amount = action.payload.amount
      state.claimable = action.payload.claimable
      state.vestingDataLoading = false
    },
    vestingDataFailure: (state) => {
      state.vestingDataError = true
      state.vestingDataLoading = false
    },
    vestingClaimRequest: (state, action: PayloadAction<{ address: string, type: string }>) => {
      state.vestingClaimError = false
      state.vestingClaimLoading = true
    },
    vestingClaimSuccess: (state) => {
      state.vestingClaimLoading = false
    },
    vestingClaimError: (state) => {
      state.vestingClaimLoading = false
      state.vestingClaimError = true
    },
    setBeneficiaryType: (state, action: PayloadAction<{ type: string }>) => {
      state.beneficiaryType = action.payload.type
    },
    getVestingPoolsRequest: (state, action: PayloadAction<{ address: string }>) => {
      state.vestingPoolsLoading = true
      state.vestingPoolsError = false
    },
    getVestingPoolsData: (state, action: PayloadAction<{ pools: VestingPool[] }>) => {
      state.vestingPools = action.payload.pools
      state.vestingPoolsLoading = false
    },
    getVestingPoolsError: (state) => {
      state.vestingPoolsError = true
      state.vestingPoolsLoading = false
    },
  },
});

export { actions as VestingActions };

export default reducer;
