import BigNumber from 'bignumber.js';
import { Decimal } from 'shared/components/Decimal';

const rewardTokenPrice = '1';

export const formatToken = (
  value: string | number | BigNumber,
  ops?: { decimalPlaces: number },
) => {
  return (+new BigNumber(value)
    .times(new BigNumber(10).pow(-18))
    .toFixed(ops?.decimalPlaces || 2)).toString();
};

export const formatMaxBalance = (value: string | number) => {
  let val = new BigNumber(value).times(new BigNumber(10).pow(-18)).toNumber();

  return val;
};

export const unFormatToken = (value: string | number | BigNumber) => {
  return new BigNumber(value).times(new BigNumber(10).pow(18)).toFixed();
};

export const toUSD = (value: string | number | BigNumber) =>
  new BigNumber(value).times(rewardTokenPrice).toNumber();

export const calcRainAPR = (
  poolTotalDeposit: string | number,
  rewardPerSecond: string | number,
  weight: number,
) => {
  return `${Decimal.format(
    +poolTotalDeposit
      ? +formatToken(poolTotalDeposit) &&
          ((+formatToken(rewardPerSecond || 0) * 60 * 60 * 24 * 365 * +formatToken(weight)) /
            +formatToken(poolTotalDeposit)) *
            100 *
            2
      : 0,
    2,
    ',',
  )}%`;
};

export const calcRaiUniAPR = (
  rewardPerSecond: string | number,
  weight: number,
  rainEstimatedPrice: number,
  LPLiquidity: number,
  lpEstimatedPrice: number,
) => {
  return `${Decimal.format(
    ((+formatToken(rewardPerSecond || 0) *
      60 *
      60 *
      24 *
      365 *
      +formatToken(weight) *
      rainEstimatedPrice) /
      ((LPLiquidity || 1) * 0.5 * lpEstimatedPrice)) *
      100,
    2,
    ',',
  )}%`;
};

export const calcEstAPR = (poolTotalDeposit: string, rewardPerSecond: string, weight: string) => {
  return (
    ((+formatToken(rewardPerSecond) * 60 * 60 * 24 * 365 * +formatToken(weight)) /
      +formatToken(poolTotalDeposit)) *
    100
  );
};

export const calcEstUniAPR = (
  rewardPerSecond: string,
  rainEstimatedPrice: number,
  LPLiquidity: number,
  lpEstimatedPrice: number,
  weight: string,
) => {
  return (
    ((+formatToken(rewardPerSecond) *
      60 *
      60 *
      24 *
      365 *
      +formatToken(weight) *
      rainEstimatedPrice) /
      ((LPLiquidity || 1) * lpEstimatedPrice)) *
    100
  );
};

export const calcDetailRainAPR = (
  rewardPerSecond: string,
  poolTotalDeposit: string,
  weight: string,
) => {
  return Decimal.format(
    ((+formatToken(rewardPerSecond || 0) * 60 * 60 * 24 * 365 * (+weight / 100)) /
      +formatToken(+poolTotalDeposit)) *
      100 *
      2,
    2,
    ',',
  );
};

export const calcDetailRainUniAPR = (
  rewardPerSecond: string,
  rainEstimatedPrice: number,
  LPLiquidity: number,
  lpEstimatedPrice: number,
  weight: string,
) => {
  return Decimal.format(
    ((+formatToken(rewardPerSecond || 0) *
      60 *
      60 *
      24 *
      365 *
      (+weight / 100) *
      rainEstimatedPrice) /
      ((LPLiquidity || 1) * 0.5 * lpEstimatedPrice)) *
      100,
    2,
    ',',
  );
};
