import { put } from 'redux-saga/effects';
import { getWeb3 } from 'helpers/getWeb3';

import ABI from 'services/contracts/EscrowPool/abi.json';
import { SnackbarActions } from 'store/reducers/snackbar';
import { RewardsActions } from 'store/reducers/rewards';
import { PoolActions } from 'store/reducers/pool';

export function* withdrawRewardSaga(action: ReturnType<typeof RewardsActions.withdrawRewardRequest>) {
  const { address, poolAddress, claimId } = action.payload;

  const web3 = getWeb3()

  try {
    //@ts-ignore
    const tokenLockContract = yield new web3.eth.Contract(ABI, poolAddress);

    yield tokenLockContract.methods.withdraw(claimId, address).send({ from: address });

    yield put(RewardsActions.withdrawRewardResponse({ claimId }));
    yield put(PoolActions.poolsDataRequest({ account: address }))
    yield put(SnackbarActions.showNotification({ type: 'success', message: 'Claim was successful' }))
  } catch (error) {
    yield put(RewardsActions.withdrawRewardError({ claimId }));
    yield put(SnackbarActions.showNotification({ type: 'error', message: 'Claim was failed' }))
  }
}
