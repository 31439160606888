import { put } from 'redux-saga/effects';
import { getWeb3 } from 'helpers/getWeb3';
import ABI from 'services/contracts/Vesting/ABI.json';
import { VestingActions } from 'store/reducers/vesting';
import axios, { AxiosResponse } from 'axios';
import { VESTING_CONTRACT } from 'constants/contracts';
import { BeneficiaryTypeKey } from 'constants/rules';
import { formatToken } from 'helpers/utils';
import { VestingPool } from 'store/reducers/vesting/types';

export function* checkIsBeneficiarySaga(
  action: ReturnType<typeof VestingActions.checkBeneficiaryRequest>,
) {
  try {
    const { address, type } = action.payload
    let isBeneficiary = false
    const beneficiaryType = type || localStorage.getItem(BeneficiaryTypeKey)

    const web3 = getWeb3()

    //@ts-ignore
    if (beneficiaryType && VESTING_CONTRACT[beneficiaryType]) {
      //@ts-ignore
      const contractAddress = VESTING_CONTRACT[beneficiaryType]
      //@ts-ignore
      const vestingContract = new web3.eth.Contract(ABI, contractAddress);

      const tokenAmount: string = yield vestingContract.methods.tokenAmounts(address).call()
      isBeneficiary = !!+tokenAmount
    }
    yield put(VestingActions.checkBeneficiarySuccess({ isBeneficiary }))

    if (type && !isBeneficiary) {
      yield put(VestingActions.addBeneficiaryRequest({ address, type }))
      yield put(VestingActions.toggleBeneficiaryTypeModal({ isOpen: true }))
    }
  } catch (error) {
    console.log(error)
    yield put(VestingActions.checkBeneficiaryFailure())
  }
}

export function* addBeneficiarySaga(
  action: ReturnType<typeof VestingActions.addBeneficiaryRequest>,
) {
  try {
    const { address, type } = action.payload


    const { data }: AxiosResponse = yield axios.get(`/user/${address}`, {
      params: { type },
    })

    if (data?.data[0]) {
      localStorage.setItem(BeneficiaryTypeKey, type)

      const { amount, sig } = data.data[0]
      const { sigParams: { r, s, v } } = JSON.parse(sig)

      //@ts-ignore
      const contractAddress = VESTING_CONTRACT[type]

      const web3 = getWeb3()

      //@ts-ignore
      const vestingContract = new web3.eth.Contract(ABI, contractAddress)
      const tokenAmount: string = yield vestingContract.methods.tokenAmounts(address).call()
      const isBeneficiary = !!+tokenAmount

      if (!isBeneficiary) {
        yield vestingContract.methods
          .addBeneficiary(address, amount, v, r, s)
          .send({ from: address })
      }

      yield put(VestingActions.addBeneficiarySuccess())
      yield put(VestingActions.checkBeneficiaryRequest({ address }))
      yield put(VestingActions.setBeneficiaryType({ type }))
    } else {
      throw new Error('Your address is not beneficiary')
    }
  } catch (error) {
    console.log(error)
    yield put(VestingActions.addBeneficiaryFailure())
  }
}

export function* vestingDataSaga(action: ReturnType<typeof VestingActions.vestingDataRequest>) {
  try {
    const { address, type } = action.payload

    const beneficiaryType = type || localStorage.getItem(BeneficiaryTypeKey)

    let amount = 0;
    let claimable = 0;

    //@ts-ignore
    if (beneficiaryType && VESTING_CONTRACT[beneficiaryType]) {
      const web3 = getWeb3()

      //@ts-ignore
      const contractAddress = VESTING_CONTRACT[beneficiaryType]
      //@ts-ignore
      const vestingContract = new web3.eth.Contract(ABI, contractAddress)
      const tokensAmount: string = yield vestingContract.methods.tokenAmounts(address).call()
      const userReleasableAmount: string
        = yield vestingContract.methods.userReleasableAmount(address).call()
      const releasedAmount: string = yield vestingContract.methods.releasedAmount(address).call()

      amount = (+tokensAmount * 10 ** -18)
      claimable = (+userReleasableAmount * 10 ** -18) - (+releasedAmount * 10 ** -18)
    }

    yield put(VestingActions.vestingDataSuccess({
      amount,
      claimable,
    }))
  } catch (error) {
    console.log(error)
    yield put(VestingActions.vestingDataFailure())
  }
}

export function* vestingClaimSaga(action: ReturnType<typeof VestingActions.vestingClaimRequest>) {
  try {
    const { address, type } = action.payload

    const beneficiaryType = type || localStorage.getItem(BeneficiaryTypeKey)

    //@ts-ignore
    if (beneficiaryType && VESTING_CONTRACT[beneficiaryType]) {
      const web3 = getWeb3()

      //@ts-ignore
      const contractAddress = VESTING_CONTRACT[beneficiaryType]
      //@ts-ignore
      const vestingContract = new web3.eth.Contract(ABI, contractAddress);

      yield vestingContract.methods.claimTokens().send({ from: address })

      yield put(VestingActions.vestingClaimSuccess())
      yield put(VestingActions.vestingDataRequest({ address, type }))
    } else {
      throw new Error('Cannot claim with your beneficiary type')
    }
  } catch (error) {
    console.log(error)
    yield put(VestingActions.vestingClaimError())
  }
}

export function* vestingRoundsSaga(
  action: ReturnType<typeof VestingActions.getVestingPoolsRequest>,
) {
  try {
    const { address } = action.payload
    const types = [
      'seed',
      'pre-seed',
      'strategic',
      'private_t1',
      'private_t2',
      '12/24',
      '12/36',
      '18/24',
      '8/4',
      '8/16',
      '8/17',
    ];

    const web3 = getWeb3()

    const contracts = types.map((type) => {
      //@ts-ignore
      return new web3.eth.Contract(ABI, VESTING_CONTRACT[type])
    })

    const pools: VestingPool[] = [];

    for (let i = 0; i < contracts.length; i++) {
      try {
        // const tokenAmount = `${3400 * 10 ** 18 }`
        const tokenAmount: string = yield contracts[i].methods.tokenAmounts(address).call()
        const isBeneficiary = !!+tokenAmount

        if (isBeneficiary) {
          const userReleasableAmount: string
            = yield contracts[i].methods.userReleasableAmount(address).call()
          const releasedAmount: string = yield contracts[i].methods.releasedAmount(address).call()

          const amount = +formatToken(tokenAmount)
          const claimable = +formatToken(userReleasableAmount) - +formatToken(releasedAmount)


          pools.push({
            type: types[i],
            amount,
            claimable,
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    yield put(VestingActions.getVestingPoolsData({ pools }))

  } catch (error) {
    console.log(error)
    yield put(VestingActions.getVestingPoolsError())
  }
}
