import { put } from "redux-saga/effects";
import { TokenActions } from "store/reducers/token";

declare const window: any;

export function* addTokenSaga(action: ReturnType<typeof TokenActions.addTokenRequest>) {
  try {
    const { tokenAddress, symbol } = action.payload
    yield window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: symbol,
          decimals: 18,
        },
      },
    })

    yield put(TokenActions.addTokenRequestSuccess())
  } catch (error) {
    console.log(error)
    yield put(TokenActions.addTokenRequestFailure())
  }
}