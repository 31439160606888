import { put } from 'redux-saga/effects';
import { getWeb3 } from 'helpers/getWeb3';

import ABI from 'services/contracts/View/ABI.json';
import TokenABI from 'services/contracts/TimeLockPool/ABI.json';
import ERC20ABI from 'services/contracts/ERC20/ABI.json';
import { VIEW_CONTRACT } from 'constants/contracts';
import { PoolActions } from 'store/reducers/pool';
import { FetchedData } from 'services/contracts/View';
import { SnackbarActions } from 'store/reducers/snackbar';
import BigNumber from 'bignumber.js';

export function* poolSaga(action: ReturnType<typeof PoolActions.poolsDataRequest>) {
  try {
    const { account } = action.payload;

    const web3 = getWeb3()


    //@ts-ignore
    const viewContract = yield new web3.eth.Contract(ABI, VIEW_CONTRACT);
    const data: FetchedData = yield viewContract.methods.fetchData(account).call();

    console.log(data) // ! dont delete

    yield put(PoolActions.poolsDataSuccess({ data }));
  } catch (error) {
    yield put(PoolActions.poolsDataFailure());
  }
}

export function* depositSaga(action: ReturnType<typeof PoolActions.depositRequest>) {
  const { pool, owner, amount, duration, receiver, isApproved, token } = action.payload;

  try {
    const MAX_UINT256 = new BigNumber(2).pow(256).minus(1).toFixed();

    const web3 = getWeb3()

    //@ts-ignore
    const erc20Contract = new web3.eth.Contract(ERC20ABI, token);
    if (!isApproved) {
      yield put(PoolActions.approveRequest())
      //@ts-ignore
      const tx = yield erc20Contract.methods.approve(pool, MAX_UINT256).send({ from: owner })

      if (tx.status) {
        yield put(PoolActions.approveSuccess())
      }
    }

    //@ts-ignore
    const tokenContract = yield new web3.eth.Contract(TokenABI, pool);

    yield tokenContract.methods.deposit(amount, duration, receiver).send({ from: owner })

    yield put(PoolActions.depositSuccesss())
    yield put(SnackbarActions.showNotification({ type: 'success', message: 'Deposit was successful' }))
  } catch (error) {
    if (!isApproved) {
      yield put(PoolActions.approveError())
    }

    yield put(PoolActions.depositError())
    yield put(SnackbarActions.showNotification({ type: 'error', message: 'Deposit was failed' }))
  }
}

export function* withdrawSaga(action: ReturnType<typeof PoolActions.withdrawRequest>) {
  const { pool, owner, receiver, depositId } = action.payload;

  try {
    yield put(PoolActions.addWithdrawProcessing(+depositId))

    const web3 = getWeb3()

    //@ts-ignore
    const tokenContract = yield new web3.eth.Contract(TokenABI, pool);

    yield tokenContract.methods.withdraw(depositId, receiver).send({ from: owner })

    yield put(PoolActions.removeWithdrawProcessing(+depositId))
    yield put(PoolActions.withdrawSuccess())
    yield put(SnackbarActions.showNotification({ type: 'success', message: 'Withdraw was successful' }))
  } catch (error) {
    yield put(PoolActions.removeWithdrawProcessing(+depositId))
    yield put(PoolActions.withdrawError())
    yield put(SnackbarActions.showNotification({ type: 'error', message: 'Withdraw was failed' }))
  }
}


export function* checkAllowanceSaga(action: ReturnType<typeof PoolActions.checkAllowanceRequest>) {
  try {
    const { token, owner, spender } = action.payload;
    const web3 = getWeb3()

    //@ts-ignore
    const erc20Contract = new web3.eth.Contract(ERC20ABI, token);
    //@ts-ignore
    const data = yield erc20Contract.methods.allowance(owner, spender).call()

    if (data) {
      yield put(PoolActions.checkAllowanceSuccesss({ status: !!+data }))
    } else {
      throw new Error('No allowance')
    }
  } catch (error) {
    yield put(PoolActions.checkAllowanceError())
  }
}
