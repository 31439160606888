import WalletConnectProvider from '@walletconnect/web3-provider';

import { bscTestnetNode, bscMainnetNode, polyTestnetNode, polyMainnetNode, ethAlchemyMainnetNode } from './url';


const getWalletConnect = () => {
  const walletConnect = new WalletConnectProvider({
    bridge: 'https://bridge.walletconnect.org',
    rpc: {
      // Mainnet
      1: `${ethAlchemyMainnetNode}${process.env.REACT_APP_ALCHEMY_PROJECT_ID}`,
      // Goerli
      // 5: `${ethAlchemyTestnetNode}${process.env.REACT_APP_ALCHEMY_PROJECT_ID}`,
      // BSC mainnet chainID value
      56: bscMainnetNode,
      // BSC testnet chainID value
      97: bscTestnetNode,
      // POLYGON mainnet chainID value
      137: polyMainnetNode,
      // POLYGON testnet chainID value
      80001: polyTestnetNode,
    },
  });

  return walletConnect;
};

const walletConnector = getWalletConnect();

export { walletConnector };
