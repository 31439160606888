// NODES
export const bscTestnetNode =
  'https://data-seed-prebsc-1-s3.binance.org:8545' ||
  'https://data-seed-prebsc-1-s1.binance.org:8545' ||
  'https://data-seed-prebsc-2-s1.binance.org:8545' ||
  'https://data-seed-prebsc-1-s2.binance.org:8545' ||
  'https://data-seed-prebsc-2-s2.binance.org:8545' ||
  'https://data-seed-prebsc-2-s3.binance.org:8545';
export const bscMainnetNode = 'https://bsc-dataseed.binance.org' || 'https://bsc-dataseed1.binance.org' || 'https://bsc-dataseed1.ninicoin.io';

export const polyTestnetNode = 'https://matic-mumbai.chainstacklabs.com' || 'https://rpc-mumbai.maticvigil.com' || 'https://rpc.maticvigil.com';
export const polyMainnetNode =
  'https://polygon-rpc.com' ||
  'https://rpc-mainnet.matic.network' ||
  'https://matic-mainnet.chainstacklabs.com' ||
  'https://rpc-mainnet.maticvigil.com' ||
  'https://rpc-mainnet.matic.quiknode.pro' ||
  'https://matic-mainnet-full-rpc.bwarelabs.com';

export const ethAlchemyTestnetNode = 'https://eth-goerli.g.alchemy.com/v2/';
export const ethAlchemyMainnetNode = 'https://eth-mainnet.alchemyapi.io/v2/';

// URLS
export const bscTestnetUrl = 'https://testnet.bscscan.com';
export const bscMainnetUrl = 'https://bscscan.com';

export const polyTestnetUrl = 'https://mumbai.polygonscan.com/';
export const polyMainnetUrl = 'https://polygonscan.com/';

export const ethTestnetUrl = 'https://goerli.etherscan.io';
export const ethMainnetUrl = 'https://etherscan.io';
