import { put } from 'redux-saga/effects';
import { EstimatedPriceActions } from 'store/reducers/estimatedPrice';
// eslint-disable-next-line import/extensions
import Coingecko from 'helpers/CoingeckoClient.js';
import { formatToken } from 'helpers/utils';
import { PoolActions } from 'store/reducers/pool';
import axios, { AxiosResponse } from 'axios';
import { cacheServerURL } from 'constants/lpDataCacheServer';

export function* estimatedPriceSaga() {
  try {
    const rainPrice: number = yield Coingecko.getEstimatedPrice('rainmaker-games');

    const { data }: AxiosResponse = yield axios.get(cacheServerURL);

    const { tokens, tokenInfo } = data

    const totalLpBalanceUSD = tokens.reduce((acc: number, curr: any) => {
      const price = curr.tokenInfo.price.rate * +formatToken(curr.balance);

      return acc + price
    }, 0)

    yield put(PoolActions.setСurrentLPLiquidity({
      total: +formatToken(data.tokenInfo.totalSupply),
    }))
    let lpPrice = totalLpBalanceUSD / +formatToken(tokenInfo.totalSupply)

    yield put(EstimatedPriceActions.getEstimatedPriceSuccess({ rainPrice, lpPrice }))
  } catch (error) {
    yield put(EstimatedPriceActions.getEstimatedPriceFailure());
  }
}