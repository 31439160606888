export const sliceAddress = (address: string) => `${address.slice(0, 4)}...${address.slice(-4)}`;

export const getAddressLink = (address: string) => {
  let urlEth = 'https://kovan.etherscan.io/address/';

  if (process.env.REACT_APP_ENV === 'production') {
    urlEth = 'https://etherscan.io/address/';
  }

  return `${urlEth}${address}`;
};
