import { call, take, takeEvery } from 'redux-saga/effects';
import { ClaimActions } from 'store/reducers/claim';
import { EstimatedPriceActions } from 'store/reducers/estimatedPrice';
import { PoolActions } from 'store/reducers/pool';
import { TokenActions } from 'store/reducers/token';
import { VestingActions } from 'store/reducers/vesting';

// Actions
import { WalletActions } from '../reducers/wallet';
import { claimSaga } from './claim';
import { estimatedPriceSaga } from './estimatedPrice';

//Saga functions
import { checkMetaMaskSaga } from './metamask';
import { checkAllowanceSaga, depositSaga, poolSaga, withdrawSaga } from './pools';
import { addTokenSaga } from './token';
import { addBeneficiarySaga, checkIsBeneficiarySaga, vestingClaimSaga, vestingDataSaga, vestingRoundsSaga } from './vesting';
import { connectWalletSaga, disconnectWalletSaga, setWalletSaga, getWalletSaga } from './wallet';
import { NetworkActions } from 'store/reducers/network';
import { changeNetworkSaga } from './network';
import { RewardsActions } from 'store/reducers/rewards';
import { withdrawRewardSaga } from './rewards';

export default function* rootSaga() {
  // Wallet
  yield takeEvery(WalletActions.disconnectWallet, disconnectWalletSaga);
  yield takeEvery(WalletActions.connectWallet, connectWalletSaga);
  yield takeEvery(WalletActions.setWalletRequest, setWalletSaga);

  yield takeEvery(EstimatedPriceActions.getEstimatedPriceRequest, estimatedPriceSaga);
  yield takeEvery(ClaimActions.claimRequest, claimSaga);

  yield takeEvery(PoolActions.poolsDataRequest, poolSaga);
  yield takeEvery(PoolActions.withdrawRequest, withdrawSaga);
  yield takeEvery(PoolActions.depositRequest, depositSaga);
  yield takeEvery(PoolActions.checkAllowanceRequest, checkAllowanceSaga);

  yield takeEvery(TokenActions.addTokenRequest, addTokenSaga);

  yield takeEvery(VestingActions.checkBeneficiaryRequest, checkIsBeneficiarySaga);
  yield takeEvery(VestingActions.addBeneficiaryRequest, addBeneficiarySaga);
  yield takeEvery(VestingActions.vestingDataRequest, vestingDataSaga);
  yield takeEvery(VestingActions.vestingClaimRequest, vestingClaimSaga);
  yield takeEvery(VestingActions.getVestingPoolsRequest, vestingRoundsSaga);

  yield takeEvery(NetworkActions.changeNetworkRequest, changeNetworkSaga);

  yield takeEvery(RewardsActions.withdrawRewardRequest, withdrawRewardSaga);

  yield call(checkMetaMaskSaga);
  yield call(getWalletSaga);
}
